@import './colors';

.circle {
  border-radius: 100px;
  width: 12px;
  height: 12px;
  margin-bottom: -2px;
  margin-right: 8px;
  background-color: $forestGreen;

  &.new {
    background-color: $statusNew;
  }

  &.open {
    background-color: $statusOpen;
  }

  &.closed {
    background-color: $statusClosed;
  }

  &.pending {
    background-color: $statusPending;
  }

  &.solved {
    background-color: $statusSolved;
  }

  &.onHold {
    background-color: $statusOnHold;
  }

  &.deleted {
    background-color: $statusDeleted;
  }
}

span.circle {
  display: inline-block;
}
