@import './../../../../shared/styles/colors.scss';
@import './../../../../shared/styles/fonts.scss';

@mixin list {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
  padding-right: 16px;
}
@mixin listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.settingsList {
  @include list;
}

.settingsListItem {
  @include listItem;
  padding: 15px 0;
  border-top: 1px solid $lightBeigeOne;
}
.settingsList:last-child {
  border-bottom: 1px solid $lightBeigeOne;
}

.settingsItemDescription {
  display: block;
}

.descriptionLink {
  display: block;
  color: $forestGreen;
}

.descriptionLinkText {
  margin-left: 10px;
  font-size: 13px;
  font-family: $OpenSans;
}

.descriptionText {
  margin-top: 10px;
  font-size: 13px;
  color: $customBlack;
  font-family: $OpenSansLight;
}

.editLink {
  display: block;
  font-size: 13px;
  font-family: $OpenSans;
}
.editSvg {
  margin-right: 5px;
}
