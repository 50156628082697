@import './../../../shared/styles/colors.scss';

ul.pagination {
  justify-content: center;

  li.page-item {
    .page-link {
      &:hover {
        background-color: $lightBeigeOne;
      }
      color: $customBlack;
      border-color: $lightBeigeTwo;
    }

    &.active {
      .page-link {
        background-color: $forestGreen;
        border-color: $forestGreen;
        color: $white;
      }
    }

    &.disabled {
      cursor: not-allowed;

      span.page-link {
        background-color: $lightBeigeOne;
      }
    }
  }
}
