@import './../../../shared/styles/colors.scss';

.service-cloud-iq {
  background: $cloudIq !important;
}
.service-empower-iq {
  background: $empowerIq !important;
}
.service-service-iq {
  background: $serviceIq !important;
}
.service-sam-iq {
  background: $samIq !important;
}
.service-market-iq {
  background: $marketIq !important;
}
.service-core-iq {
  background-color: $coreIq !important;
}
.service-optima-iq {
  background-color: $optimaIq !important;
}
.service-software-downloads {
  background-color: $softwareDownloads !important;
}
