@import './colors';
@import './fonts';

.nav-tabs-blue {
  &.nav-tabs {
    border-bottom: 3px solid $forestGreen;

    > .nav-link {
      height: 40px;
      background-color: $lightBeigeTwo;
      border-radius: 0px;
      margin-right: 8px;
      border-bottom-color: $forestGreen;

      &.active {
        font-family: $OpenSans;
        font-size: 13px;
        color: #ffffff;
        background-color: $forestGreen;
        border-color: $forestGreen;
      }
    }
  }
}

.nav-tabs-underlined {
  &.nav,
  &.nav-tabs {
    border-bottom: 1px solid $lightBeigeTwo;
    margin-left: 0;
    margin-right: 0;

    > .nav-link {
      font-family: $OpenSans;
      font-size: 13px;
      color: $greyBeigeOne;
      margin-bottom: -1px;

      background-color: transparent;
      border-color: transparent;

      &.active {
        color: $forestGreen;
        border-bottom: 2px solid $forestGreen;
      }
    }
  }
}
