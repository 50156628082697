@import './../../../../shared/styles/colors.scss';

.newsTile {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 350px;
  margin-bottom: 30px;
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}

.newsImage {
  display: block;
  position: absolute;
  max-width: 100%;
  max-height: 39px;
  top: 0;
  left: 0;
}

.imageContainer {
  width: 100%;
  height: 190px;
  min-height: 190px;
  position: relative;
  background-size: cover;
  background-position: center;
  // overflow: hidden;
}

.contentContainer {
  padding: 20px;
  overflow: hidden;
}

.description {
  display: block;
  overflow: hidden;
  position: relative;
  max-height: 40px;
}

.allNewsLink {
  margin-top: 0;
  @media (min-width: 768px) {
    margin-top: 10px;
  }
}
