/* 
 * Primary color palette
 * We use 
 *   'blue' for primary headings, actions, buttons, links, for indicating progress, and representing authentication. 
 *   'custom black' is used primarily for body text and headings. 
 *   'light blue 1' is used for body page backgrounds and as a contrast to 'white'.
 *   'white' is used for container background color and anything that is white.
 */
$forestGreen: #094e5d; //Background color (footer) , titles, icones
$customBlack: #404040;
$lightBeigeOne: #f2f1ed; //Background color
$white: #fff;

/* 
 * Secondary color palette
 * Our secondary palette is made up of green, red and orange. 
 * Each color is selected with purpose in order to provide meaningful feedback within our products. 
 * These colors are often used as buttons and messages.
 */
$green: #2aa759; //Color on status (active), save buttons, approved messages
$red: #c13b43;
$orange: #e0764c;

/* 
 * Neutrals
 * Neutrals have varying degrees of saturation that allow for the appropriate level of warmth across the product. 
 * Typically they are used for text and subtle backgrounds when we don't want to draw too much attention to a 
 * particular touchpoint or convey information such as "to do" or "disabled".
 */
$lightBeigeTwo: #e2e8ea;
$lightBeigeThree: #cad9de;
$greyBeigeOne: #91b3be;
$greyBeigeTwo: #6693a8;

$tagBackground: #dfe9ec;

/* 
 * Interactive color levels
 * Interactive components that uses the primary color palette often have a state where you interact with the mouse, 
 * so darker colors for the primary color palette are used as hover states.
 */
$lightBlue: #dfe9ec;
$forestGreenLight: #4e7b88; //Hover color
$greenDark: #1b733c; //Unknown, might be a hover?
$redDark: #9c1f26;
$orangeDark: #b24d26;

// Misc
$boxShadowColor: #ccc; // should we have box shadow?
$greyStatus: #a1a1a1; // should we maybe make use of one of the existing colors?
$offWhite: #f7f7f7; //for some hovers

// Services
$cloudIq: #3071ad;
$serviceIq: #c03333;
$samIq: #c03333;
$marketIq: #c03333;
$empowerIq: #2d5f8b;
$coreIq: #53881e;
$optimaIq: #5a97fa;
$softwareDownloads: #307285;

//Ticket status colors
$statusNew: #faaf40;
$statusOpen: #e0764c;
$statusPending: #91beba;
$statusSolved: $green;
$statusOnHold: $forestGreen;
$statusDeleted: $redDark;
$statusClosed: $customBlack;
