@import '../../styles/colors.scss';
@import '../../styles/fonts.scss';

.breadcrumb {
  padding: 0;
  background-color: inherit;
  margin-bottom: 40px;

  li {
    font-family: $OpenSans;
    font-size: 12px;
    letter-spacing: 0.16px;

    a {
      font-family: $OpenSans;
      color: $forestGreen;
    }
  }
}

.breadcrumb-item + .breadcrumb-item:before {
  content: '>';
}
