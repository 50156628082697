@import './../../styles/colors.scss';
@import './../../styles/fonts.scss';
.cFileUploader {
  .dropArea {
    border: dashed 1px $greyBeigeOne;
    background-color: $lightBeigeOne;
    border-top: 1px solid $forestGreen;

    /* Center content inside file uploader */
    display: flex;
    text-align: center;
    align-items: center;
    flex: auto;
    justify-content: center;

    &:hover {
      border: solid 1px $forestGreen;
    }

    margin-bottom: 10px;
  }

  .filePreviewContainer {
    margin-bottom: 10px;
    background-color: #e0eaf3;

    padding: 11px 20px 9px 20px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    .errorText {
      color: $red;
    }

    .remove {
      color: $greyBeigeOne;
      margin-left: 10px;
      cursor: pointer;

      &:hover {
        color: #231f20;
      }
    }
  }

  .fileUploaderInputLabel {
    font-family: $OpenSans;
    font-weight: normal;
    font-size: 12px;
    text-align: center;
    color: $forestGreen;

    margin-bottom: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    > div {
      padding: 43px;
    }

    h4 {
      font-family: $OpenSans;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: $forestGreen;
      text-decoration: underline;
      margin-bottom: 16px;
    }

    p {
      font-family: $OpenSans;
      font-size: 20px;
      font-weight: 300;
      letter-spacing: -0.3px;
      color: $customBlack;
    }

    span {
      font-family: $OpenSans;
      font-size: 12px;
      font-style: italic;
      text-align: center;
      color: $greyBeigeOne;
      margin-top: 8px;
    }
  }
}

.custom-preview-file-name {
  font-family: $OpenSans;
  font-size: 13px;
  line-height: 1.54;
  letter-spacing: normal;
  color: $customBlack;
}

.file-button {
  background-color: $forestGreen;
  color: white !important;
  font-weight: 400 !important;
  font-family: $OpenSans;
  cursor: pointer;
  height: 32px;
  line-height: 30px;
  padding: 0 20px;
  font-size: 13px;
  border-radius: 100px;
}
.file-button:hover {
  background-color: $forestGreenLight;
}
