@import './../../styles/colors.scss';
@import './../../styles/fonts.scss';

.navbarBrand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

.header {
  background-color: white;
}

.navBar {
  height: 90px;
}

.logo {
  max-width: 210px;
  width: 100%;
  height: 100%;
}

.toggleButton {
  outline: none;
  border: none;
  background: transparent;
  color: $forestGreen;
}
.toggleButton:hover,
.toggleButton:active,
.toggleButton:focus {
  color: $forestGreenLight;
  outline: none;
  border: none;
  background: transparent;
}

.userName,
.userNameSubtitle {
  font-size: 13px;
  font-family: $OpenSansLight;
  text-align: right;
}
.userName {
  line-height: 1.8;
}
.userNameSubtitle {
  color: $greyBeigeOne;
}
.profileSection {
  display: flex;
  align-items: center;
}

.profilePicture {
  display: block;
  background-image: url('./../../../shared/images/profile-image-default.png');
  background-size: cover;
  background-position: center center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 15px;
}

.dropDownContainer {
  margin-left: 15px;
}
.dropDownOverrides {
  position: absolute !important;
  margin-top: 15px;
}
.dropDownItemOverrides {
  font-family: $OpenSansLight;
  font-size: 13px;
}
