@import '../../../../shared/styles/colors.scss';
@import '../../../../shared/styles/fonts.scss';

.ticket-details-container {
  .card-title {
    padding: 12px 0;
    border-bottom: 2px solid $lightBeigeTwo;
  }

  .card-text {
    &.comment-body {
      white-space: pre-line;
    }

    color: $customBlack;
  }

  .card-link {
    font-family: $OpenSans;
  }
}
