@import './../../../../shared/styles/colors.scss';
@import './../../../../shared/styles/fonts.scss';

@mixin list {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
}
@mixin listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.settingsList {
  @include list;
}

.settingsListLeft {
  padding-right: 16px;
}

.settingsListRight {
  padding-left: 16px;
}

.settingsListItem {
  @include listItem;
  border-top: 1px solid $lightBeigeOne;
}
.settingsListItem:last-child {
  border-bottom: 1px solid $lightBeigeOne;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;

  h3 {
    margin-bottom: 0px;
  }
}

.flexEnd {
  justify-content: flex-end;
}

.rightColumnWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.buttons-wrapper {
  margin-top: 20px;
  height: 32px;
  display: flex;
  justify-content: flex-end;
}
