@import './../../../../shared/styles/colors.scss';
@import './../../../../shared/styles/fonts.scss';

@mixin list {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
}
@mixin listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.contactsList {
  @include list;
}
.contactsListItem {
  @include listItem;
  padding: 15px 15px 15px 0;
  border-bottom: 1px solid $lightBeigeOne;
}
.contactsListItem:first-child {
  border-top: 1px solid $lightBeigeOne;
}

.profileCard {
  display: flex;
  align-items: center;
}
.profileImage {
  width: 50px;
  height: 50px;
  background: $forestGreen;
  border-radius: 50%;
  margin-right: 10px;
  overflow: hidden;

  color: white;
  font-size: 30px;
  text-align: center;
}
.contactName {
  font-size: 13px;
  font-family: $OpenSans;
  color: $customBlack;
}
.contactPosition {
  font-size: 13px;
  font-family: $OpenSansLight;
  color: $greyBeigeOne;
}
.contactInfo {
  font-size: 13px;
  color: $customBlack;
  min-width: 230px;
}
