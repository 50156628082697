h1,
h2,
h3,
h4 {
  margin-top: 0;
  color: $forestGreen;
}
h1,
h2,
h3 {
  font-family: $OpenSansLight;
}

h1 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 300;
}

h2 {
  font-size: 26px;
  line-height: 32px;
  font-weight: 300;
}

h3 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
}

h4 {
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
}

h3,
h4,
h5,
p,
a,
li,
th,
td,
input,
select,
a.btn,
label,
.heading,
.panel-heading {
  font-family: $OpenSans;
}

p,
.body1 {
  font-size: 15px;
  line-height: 24px;
  color: $customBlack;
}
.body2 {
  font-size: 13px;
  line-height: 20px;
  color: $customBlack;
}

.subtitle1 {
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
}

.subtitle2 {
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
}
