@font-face {
  font-family: 'OpenSans';
  src: url('./../fonts/OpenSans.eot'); /* IE9 Compat Modes */
  src: url('./../fonts/OpenSans.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./../fonts/OpenSans.woff') format('woff'),
    /* Pretty Modern Browsers */ url('./../fonts/OpenSans.ttf')
      format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'OpenSans-Light';
  src: url('./../fonts/OpenSans-Light.eot'); /* IE9 Compat Modes */
  src: url('./../fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./../fonts/OpenSans-Light.woff') format('woff'),
    /* Pretty Modern Browsers */ url('./../fonts/OpenSans-Light.ttf')
      format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: url('./../fonts/OpenSans-Bold.eot'); /* IE9 Compat Modes */
  src: url('./../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./../fonts/OpenSans-Bold.woff') format('woff'),
    /* Pretty Modern Browsers */ url('./../fonts/OpenSans-Bold.ttf')
      format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Niveau-Grotesk-Bold';
  src: url('./../fonts/Niveau-Grotesk-Bold.eot'); /* IE9 Compat Modes */
  src: url('./../fonts/Niveau-Grotesk-Bold.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./../fonts/Niveau-Grotesk-Bold.woff') format('woff'),
    /* Pretty Modern Browsers */ url('./../fonts/Niveau-Grotesk-Bold.ttf')
      format('truetype'); /* Safari, Android, iOS */
}

$OpenSans: 'OpenSans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$OpenSansLight: 'OpenSans-Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$OpenSansBold: 'OpenSans-Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;

$NiveauGrotesk: 'Niveau-Grotesk-Bold';
