@import './../../../shared/styles/colors.scss';
@import './../../../shared/styles/fonts.scss';

.cSteps {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  border-radius: 4px;

  > .step {
    /* Apperence */
    cursor: text;
    border: 0;
    border-right: 1px solid #c0d4e6;
    background-color: $lightBeigeTwo;
    color: $greyBeigeTwo; //rgba(48, 113, 173, 0.5);
    border-radius: 4px;
    padding: 12px 21px 14px 20px;
    font-size: 13px;
    font-family: $OpenSans;
    text-align: left;

    /* Display steps horizontal */
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;

    /* Align text in center verticaly and share width */
    display: -ms-flexbox;
    display: flex;
    justify-content: center;

    &.active {
      background-color: $forestGreen;
      color: #ffffff;
    }

    &.disabled {
      background-color: $lightBeigeTwo;
      color: $greyBeigeOne;
    }

    &.clickable {
      color: $forestGreen;
      background-color: $lightBeigeThree; // #d1dbe9;
      cursor: pointer;
    }
  }

  > .step:last-child {
    border-right: none;
  }

  > .step:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  > .step:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
