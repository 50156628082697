@import './../../../../shared/styles/colors.scss';
@import './../../../../shared/styles/fonts.scss';

.ticket-comment-wrapper {
  border: 1px solid $lightBeigeTwo;
  border-radius: 5px;
}

.ticket-comment {
  padding: 20px;
  border-top: 1px solid $lightBeigeTwo;
}
.ticket-comment:first-child {
  border-top: none;
}
.ticket-comment:nth-child(even) {
  background-color: $lightBeigeOne;
}

.ticket-comment-timestamp {
  font-size: 11px;
  font-family: $OpenSans;
  color: $customBlack;
  letter-spacing: 0.12px;
}

.attachment-link {
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.12px;
}

.attachment-icon {
  margin-right: 5px;
}
