@import './../../../../shared/styles/colors.scss';
@import './../../../../shared/styles/fonts.scss';

.gridLayout {
  margin-top: 30px;
}

.gridTileBrick {
  transition-property: all !important;
}
.gridTileBrick:hover {
  margin-top: -5px;
}

.serviceTile {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.service-logo {
  display: block;
  position: absolute;
  max-width: 90%;
  height: 45px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.serviceLogoText {
  font-family: $NiveauGrotesk;
  font-size: 34px;
  color: white;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: capitalize;
}

.logoContainer {
  background: $forestGreen;
  width: 100%;
  height: 30%;
  position: relative;
}

.contentContainer {
  padding: 20px;
}
