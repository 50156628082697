@import './../../../../shared/styles/colors.scss';
@import './../../../../shared/styles/fonts.scss';

.ticketCardList {
  @media (min-width: 1024px) {
    display: flex;
    flex-flow: row wrap;
    margin-right: -12px;
    margin-left: -12px;
    margin-bottom: 20px;
  }
}

.ticketCard {
  align-items: center;
  position: relative;
  display: flex;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
  min-height: 100px;
  border: 0;
  text-align: left;
  width: 100%;
}

.ticketCardTileBrick {
  margin-bottom: 12px;
  @media (min-width: 1024px) {
    flex: 0 0 calc(50% - 24px);
    margin-right: 12px;
    margin-bottom: 20px;
    margin-left: 12px;
  }
}
.ticketCardTileBrick:focus {
  outline: 1px solid $forestGreen;
}
.ticketCardTileBrick:active {
  box-shadow: none !important;
  transform: translateY(0) !important;
  outline: 1px solid $forestGreen;
}

.tileBordered {
  border: solid 1px $lightBeigeTwo;
  width: 400px;
}
.tileBordered:hover {
  border: solid 1px $forestGreen;
  text-decoration: none;
}

.ticketContent {
  display: flex;
  align-items: center;
  padding: 15px 0;
}

.ticketImage {
  width: 100px;
  min-width: 100px;
  overflow: hidden;
  text-align: center;
  font-size: 40px;
  color: $forestGreen;
}

.ticketHeading {
  font-family: $OpenSans;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.69;
  color: $customBlack;
  margin-bottom: 4px;
}

.ticketText {
  font-family: $OpenSans;
  font-size: 12px;
  line-height: 1.54;
  color: $greyBeigeOne;
  margin: 0;
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .ticketCardList {
    .ticketCard {
      margin-bottom: 12px;

      .ticketContent .ticketImage {
        width: 80px;
        min-width: 80px;
      }
    }
  }
}
