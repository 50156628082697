@import './../../../../shared/styles/colors.scss';
@import './../../../../shared/styles/fonts.scss';

.tags-wrapper {
  display: flex;
  margin-bottom: 35px;
}

.tag {
  font-size: 12px;
  font-family: $OpenSans;
  font-weight: 600;
  background: $tagBackground;
  border-radius: 4px;
  color: $forestGreen;
  margin-right: 8px;
  padding: 6px 12px;
}
.active {
  background: $forestGreen;
  color: white;
}

.tag-link {
  color: $forestGreen;
}
.tag-link:hover {
  text-decoration: underline;
  color: $forestGreen;
}

.tags-remove-icon {
  margin-left: 15px;
  cursor: pointer;
}
.tags-remove-icon:hover {
  color: $forestGreenLight;
}
