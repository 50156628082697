@import './../../../../shared/styles/colors.scss';
@import './../../../../shared/styles/fonts.scss';

.thankYouAlert {
  font-family: $OpenSans;
  font-size: 18px;
  line-height: 1.33;
  color: $green;
  border: solid 1px #d8eac9;
  background-color: #dff0d8;
  padding: 24px 28px;
}
.iconStyles {
  color: $green;
  font-size: 27px;
  vertical-align: text-top;
  margin-right: 12px;
}

.thankYouPage {
  padding: 28px !important;
}

.thankYouStatusBox {
  display: flex;
  justify-content: flex-end;
}
