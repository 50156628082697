@import './../../../shared/styles/colors.scss';

.expansionPanelWrapper {
  margin-top: 20px;
}

.cardHeader {
  padding: 0;
}
.toggleButton {
  padding: 15px 20px;
  font-size: 13px;
  width: 100%;
  text-align: left;
  background-color: $forestGreen !important;
  border-color: $forestGreen !important;
  border-radius: 5px 5px 0 0;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.toggleButton:hover,
.toggleButton:active,
.toggleButton:focus {
  color: white;
  background-color: $forestGreenLight !important;
  border-color: $forestGreenLight !important;
  box-shadow: none !important;
}

.expansion-panel-divider {
  display: none;
  position: absolute;
  top: 53px;
  width: 1px;
  height: 100%;
  background-color: $lightBeigeOne;
  @media (min-width: 768px) {
    display: block;
  }
}

.expansionPanelCardBody {
  padding: 28px !important;
}
