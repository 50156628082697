@import './colors';
@import './fonts';
@import './typography';
@import './tabs';
@import './tables';
@import './forms';
@import './circle';

html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

@media (max-width: 1024px) {
  .desktop-only {
    display: none;
  }
}

body {
  background-color: $lightBeigeOne;
  // font-family: $OpenSans;
}

#page-container {
  margin-top: 30px;
}

a {
  color: $forestGreen;
  &:hover {
    color: $forestGreenLight;
  }
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

@media (min-width: 1284px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1284px;
  }
}

.section-margin {
  margin-top: 60px;
}

.section-title-spacing {
  margin-top: 30px;
}

.component-spacing {
  margin-bottom: 20px;
}

.steps-spacing {
  margin-bottom: 24px;
}

.tile-brick {
  transition: all 100ms ease-out;
  display: block;
}
.tile-brick:hover {
  text-decoration: none;
  transform: translateY(-5px);
  box-shadow: 0px 5px 10px $boxShadowColor;
}

.nowrap {
  white-space: nowrap;
}
