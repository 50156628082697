@import './../../styles/colors.scss';
@import './../../styles/fonts.scss';

.footer {
  background-color: $forestGreen;
  padding-top: 50px;
  padding-bottom: 60px;
  letter-spacing: 1px;
  height: auto;
  margin-top: 30px;
}
@mixin footerFontBase {
  color: $white;
  font-weight: 100;
  font-family: $OpenSansLight;
}

.footerTitle {
  @include footerFontBase();
}
.footerLink {
  @include footerFontBase();
  font-size: 13px;
}
.footerLink:hover {
  color: $offWhite;
}
