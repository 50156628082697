@import './../../../../shared/styles/colors.scss';
@import './../../../../shared/styles/fonts.scss';

.serviceTile {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
}

.other-services-tile-brick {
  height: 95%;
}

.service-logo {
  display: block;
  position: absolute;
  max-width: 90%;
  height: 25px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.serviceLogoText {
  font-family: $NiveauGrotesk;
  font-size: 24px;
  color: white;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: capitalize;
}

.logoContainer {
  width: 100%;
  height: 75px;
  position: relative;
}

.contentContainer {
  padding: 20px;
  min-height: 130px;
}
