@import './../../../shared/styles/colors.scss';
@import './../../../shared/styles/fonts.scss';

@mixin listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.mediaObject {
  padding: 15px 0;
  @include listItem;

  .settingsItemDescription {
    display: block;
    padding-right: 10px;

    > svg,
    > i {
      margin-right: 9px;
    }

    .descriptionLink {
      display: block;
      color: $forestGreen;
    }

    .descriptionLinkText {
      font-size: 13px;
      font-family: $OpenSans;
    }

    .descriptionText {
      margin-top: 10px;
      font-size: 13px;
      color: $customBlack;
      font-family: $OpenSansLight;
    }
  }

  .editLink {
    display: block;
    font-size: 13px;
    font-family: $OpenSans;
  }

  .editSvg {
    margin-right: 5px;
  }
}

.mediaObjectLink:hover {
  background-color: $offWhite;
  width: calc(100% + 28px);
  padding: 15px 14px 15px 14px;
  margin: 0 -14px 0 -14px;
}
