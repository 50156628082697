

.plus {
    svg {
        vertical-align: inherit;
    }
}

/*.plus {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: linear-gradient(#fff,#fff), linear-gradient(#fff,#fff), transparent;
  background-position: center;
  background-size: 100% 1px,1px 100%; 
  background-repeat: no-repeat;
}*/


/*.plus {
  position: relative;
  width: 10px;
  height: 10px;
  background: transparent;
  display: inline-block;
  margin-right: 0px;
  vertical-align: text-top;
}

.plus:before,
.plus:after {
  content: "";
  position: absolute;
  background: #fff;
}


.plus:before {
  left: 50%;
  top: 0px; 
  bottom: 0px;
  width: 1px;
  transform: translateX(-50%);
}


.plus:after {
  top: 50%;
  left: 0px;
  right: 0px;
  height: 1px;
  transform: translate(-50%, 50%);
}*/


/*.plus {
  display: flex;
  align-items: center;
  margin: 10px;
}

.plus:before {
  content: '';
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid black;
  font-size: 30px;
  color: black;
}


.plus:before {
  content: '+';
}*/

