@import './../../styles/colors.scss';
@import './../../styles/fonts.scss';

.cBtn {
  height: 44px;
  padding: 0 40px;
  line-height: 42px;
  letter-spacing: normal;
  text-align: center;
  font-family: $OpenSans;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  display: inline-block;
  border: 0;

  &.disabled,
  &:disabled {
    opacity: 0.65;
    box-shadow: none;

    &:hover {
      opacity: 0.65;
      box-shadow: none;
    }
  }

  &:focus {
    outline: none;
  }

  &.btnRound {
    border-radius: 100px;
  }
  /*Sizes*/
  &.btnSmall {
    height: 32px;
    line-height: 30px;
    padding: 0 16px;
  }

  &:hover {
    text-decoration: none;
  }

  .dots {
    &::after {
      display: inline-block;
      animation: ellipsis 1.5s infinite;
      content: '.';
      width: 1em;
      text-align: left;
      margin-left: 1px;
    }

    @keyframes ellipsis {
      0% {
        content: '.';
      }

      25% {
        content: '..';
      }

      50% {
        content: '...';
      }

      75% {
        content: ' ';
      }
    }
  }
}

.linkBtn {
  color: $customBlack;
  font-family: $OpenSans;
  border: none;
  outline: none;
  border-radius: 0;
  background: none;
  font-size: 13px;
}
.linkBtn:active,
.linkBtn:focus {
  outline: none;
  border: none;
}
.linkBtn:hover {
  text-decoration: underline;
  color: $forestGreenLight;
}

@mixin buttonColor($defaultColor, $hoverColor, $textColor) {
  background-color: $defaultColor;
  color: $textColor;
  &.disabled,
  &:disabled {
    color: $textColor;
    background-color: $defaultColor;
    border-color: $defaultColor;
    &:hover {
      background-color: $defaultColor;
      border-color: $defaultColor;
    }
  }
  &:focus {
    box-shadow: 0 0 0 1px $hoverColor;
  }
  &:hover {
    background-color: $hoverColor;
    color: $textColor;
  }
}

.blue {
  @include buttonColor($forestGreen, $forestGreenLight, #fff);
}

.green {
  @include buttonColor($green, $greenDark, #fff);
}

.red {
  @include buttonColor($red, $redDark, #fff);
}

.lightBlue {
  @include buttonColor($lightBlue, darken($lightBlue, 5%), $forestGreen);
}
